<mat-sidenav-container class="example-container">
    <mat-sidenav #drawer
                 mode="over">
        <ng-container *ngTemplateOutlet="menuItems"></ng-container>
    </mat-sidenav>

    <mat-toolbar color="primary">
        <div class="mobile-spacer">
            <button mat-button
                    class="menu-button"
                    (click)="drawer.toggle()">
                <mat-icon>menu</mat-icon>
            </button>
        </div>

        <ng-container *ngTemplateOutlet="menuItems"></ng-container>

        <span class="mobile-spacer">&nbsp;</span>
    </mat-toolbar>

    <div class="content-container">
        <router-outlet></router-outlet>
    </div>
</mat-sidenav-container>

<ng-template #menuItems>
    <a mat-button
       class="home-button"
       routerLink="/">The Scoreboard</a>

    <ng-container *ngIf="user">
        <ng-container *ngTemplateOutlet="menuItem; context: { link: '/games', icon: 'casino', text: 'Games' }">
        </ng-container>
        <ng-container *ngTemplateOutlet="menuItem; context: { link: '/players', icon: 'groups', text: 'Players' }">
        </ng-container>
    </ng-container>

    <ng-container *ngTemplateOutlet="menuItem; context: { link: '/login', icon: 'person', text: user ? 'Account' : 'Log In' }">
    </ng-container>
</ng-template>

<ng-template #menuItem
             let-link="link"
             let-icon="icon"
             let-text="text">
    <a mat-button
       class="game-button mx-1"
       [routerLink]="link"
       routerLinkActive="active-link"
       [routerLinkActiveOptions]="{ exact: true }"
       (click)="drawer.close()">
        <mat-icon>{{ icon }}</mat-icon>
        {{ text }}
    </a>
</ng-template>