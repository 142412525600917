<ng-container *ngIf="!loading; else loadingRef">
    <div *ngIf="player"
         class="ta-c">
        <div class="mat-display-1 mt-4 mb-2 ta-c">{{ player.name }}</div>
        <div>Created on: {{ player.dateCreated | date }}</div>

        <ng-container *ngIf="hasStats">
            <ng-container *ngTemplateOutlet="statsRef"></ng-container>
        </ng-container>

        <div class="mat-headline mt-4 mb-2 ta-c">Recent Games</div>
        <app-game-details *ngFor="let game of games"
                          [key]="game.key || ''"
                          [game]="game"></app-game-details>
    </div>
</ng-container>

<ng-template #loadingRef>
    <app-loading></app-loading>
</ng-template>

<ng-template #statsRef>
    <!-- MAIN STATS -->
    <hr class="mt-3">
    <div class="stats-container">
        <div class="mat-headline mt-2 mb-1 ta-c">
            Total Games:
            <span class="total-games">{{ stats.gameCount }}</span>
        </div>

        <div class="mat-headline mt-1 mb-2 ta-c">
            Overall Win Rate:
            <span class="total-games">{{ toPercentage(stats.overallWinPercentage) }}</span>
        </div>
    </div>

    <!-- CHARTS -->
    <hr class="mt-2">
    <div class="stats-container">
        <div class="mat-headline my-2 ta-c">Favourite Games</div>

        <div class="chart-container">
            <ngx-charts-bar-vertical [results]="stats.favouriteGames"
                                     [xAxis]="true"
                                     [yAxis]="true"
                                     [yAxisTickFormatting]="toRoundNumber"
                                     [scheme]="colourScheme"
                                     style="fill: #fff;">
                <ng-template #tooltipTemplate
                             let-model="model">
                    <div class="chart-tooltip-name">{{model.name}}</div>
                    <div class="chart-tooltip-value">{{model.value}} games</div>
                </ng-template>
            </ngx-charts-bar-vertical>
        </div>

        <div class="mat-headline mt-4 mb-2 ta-c">Best Win Rates</div>

        <div class="chart-container">
            <ngx-charts-bar-vertical [results]="stats.bestGames"
                                     [xAxis]="true"
                                     [yAxis]="true"
                                     [yAxisTickFormatting]="toPercentage"
                                     [scheme]="colourScheme"
                                     style="fill: #fff;">
                <ng-template #tooltipTemplate
                             let-model="model">
                    <div class="chart-tooltip-name">{{model.name}}</div>
                    <div class="chart-tooltip-value">{{ toPercentage(model.value) }}</div>
                </ng-template>
            </ngx-charts-bar-vertical>
        </div>
    </div>

    <hr class="mt-4">
</ng-template>