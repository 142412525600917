import { IDBEntity } from 'src/app/shared/models/db-entity';
import { Identifyable } from 'src/app/shared/models/identifyable';

export interface Player extends IDBEntity {
    name: string;
    games: string[];
}

export interface PlayerScore {
    id: string;
    name: string;
    score: number;
}

export interface PlayerWithKey extends Player, Identifyable { }

export const newPlayerKey = '#newPlayer';
