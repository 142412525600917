import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

import { DisplayUser } from 'src/app/auth/models/user';
import { AuthState } from 'src/app/auth/store/auth.state';
import { getBestPlayersFor } from 'src/app/functions/game.functions';
import { AddPlayerComponent } from 'src/app/players/dialogs/add-player/add-player.component';
import { AddPlayerComponentData } from 'src/app/players/dialogs/add-player/add-player.data';
import { newPlayerKey, Player, PlayerWithKey } from 'src/app/players/models/player';
import { IDBEntity } from 'src/app/shared/models/db-entity';

import { Store } from '@ngxs/store';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AddBoardGameComponent } from '../dialogs/add-board-game/add-board-game.component';
import { AddBoardGameComponentData } from '../dialogs/add-board-game/add-board-game.data';
import { AddGameRequest } from '../models/add-game-request';
import { BoardGame, BoardGameWithKey } from '../models/board-game';
import { Game } from '../models/game';
import { AddGame, RefreshScoreboardData } from '../store/scoreboard.actions';
import { ScoreboardState } from '../store/scoreboard.state';

const newGameKey = '#newGame';

@Component({
    selector: 'app-add-game',
    templateUrl: './add-game.component.html',
    styleUrls: ['./add-game.component.scss']
})
@UntilDestroy()
export class AddGameComponent implements OnInit {
    public boardGames: BoardGameWithKey[] = [];
    public players: PlayerWithKey[] = [];

    public form: FormGroup;
    public error = '';
    public maxDate = new Date();

    public newGameKey = newGameKey;
    public newPlayerKey = newPlayerKey;

    public tieBreakPlayers: PlayerWithKey[] = [];

    private user: DisplayUser | null = null;

    get selectedPlayers(): FormArray {
        // tslint:disable-next-line:no-string-literal
        return (this.form.controls['players'] as FormArray);
    }

    constructor(
        private store: Store,
        private fb: FormBuilder,
        private router: Router,
        private dialog: MatDialog,
        private snackbar: MatSnackBar
    ) {
        this.form = this.createForm();
        this.addPlayer();
    }

    ngOnInit(): void {
        this.getStoreData();
    }

    private createForm(): FormGroup {
        return this.fb.group({
            date: new FormControl(new Date(), [Validators.required]),
            gameId: new FormControl(null, [Validators.required]),
            players: this.fb.array([], [Validators.minLength(1)]),
            tieBreakPlayer: new FormControl(null)
        });
    }

    private getStoreData(): void {
        this.store
            .select(ScoreboardState.boardGamesArray)
            .pipe(untilDestroyed(this))
            .subscribe(boardGames => this.boardGames = boardGames.sort((a, b) => a.name > b.name ? 1 : -1));

        this.store
            .select(ScoreboardState.playersArray)
            .pipe(untilDestroyed(this))
            .subscribe(players => this.players = players.sort((a, b) => a.name > b.name ? 1 : -1));

        this.store
            .select(AuthState.user)
            .pipe(untilDestroyed(this))
            .subscribe(user => this.user = user);

        this.store.dispatch(new RefreshScoreboardData());
    }

    public addPlayer(): void {
        const player = this.fb.group({
            id: new FormControl(null, [Validators.required]),
            score: new FormControl(0, [Validators.required])
        });

        this.selectedPlayers.push(player);
    }

    public onGameSelected(selectionChange: MatSelectChange): void {
        if (selectionChange.value !== newGameKey) {
            return;
        }

        this.form.get('gameId')?.setValue(null);

        const data: AddBoardGameComponentData = {
            title: 'Add Game',
            label: 'Game Name',
            boardGameNames: Object.values(this.boardGames).map(bg => bg.name)
        };

        this.dialog.open(AddBoardGameComponent, { data, minWidth: '50%', width: '30rem' })
            .afterClosed()
            .subscribe((value: string) => {
                if (!(value || '').length) {
                    return;
                }

                this.boardGames = this.boardGames.filter(game => game.key !== newGameKey);
                this.boardGames.push({ key: newGameKey, name: value, games: [] });
                this.form.get('gameId')?.setValue(newGameKey);
            });
    }

    public onPlayerSelected(selectionChange: MatSelectChange, index: number): void {
        if (selectionChange.value !== newPlayerKey) {
            this.setTieBreakValues();
            return;
        }

        const playerControl = this.selectedPlayers.controls[index];
        if (playerControl === null) {
            return;
        }

        playerControl.get('id')?.setValue(null);

        const data: AddPlayerComponentData = {
            title: 'Add Player',
            label: 'Player Name',
            playerNames: this.players.map(player => player.name)
        };

        this.dialog.open(AddPlayerComponent, { data, minWidth: '50%', width: '30rem' })
            .afterClosed()
            .subscribe((value: string) => {
                if (!(value || '').length) {
                    return;
                }

                const key = newPlayerKey + value;
                this.players.push({ key, name: value, games: [] });
                playerControl.get('id')?.setValue(key);

                this.setTieBreakValues();
            });
    }

    public playerIsSelected(thisControlIndex: number, playerId: string): boolean {
        const thisControl = this.selectedPlayers.at(thisControlIndex);
        return this.selectedPlayers.controls.some(control => control !== thisControl && control.get('id')?.value === playerId);
    }

    public removePlayer(i: number): void {
        this.selectedPlayers.removeAt(i);
        this.setTieBreakValues();
    }

    public setTieBreakValues(): void {
        this.tieBreakPlayers = this.getWinningPlayers();
    }

    public save(): void {
        this.error = '';
        if (!this.form.valid) {
            this.error = 'Fix form errors before continuing';
            return;
        }

        if (!this.user) {
            this.snackbar.open('Current user could not be resolved... Please log out and log back in');
            return;
        }

        const creationDetails: IDBEntity = { dateCreated: new Date(), createdBy: this.user.id };

        const game: Game = {
            gameId: this.form.get('gameId')?.value,
            date: this.form.get('date')?.value,
            players: this.selectedPlayers.controls
                .map(control => control.get('id')?.value)
                .filter(id => !!id),
            scores: this.getScores(),
            tieBreakPlayerId: this.form.get('tieBreakPlayer')?.value,
            ...creationDetails
        };

        const tieBreakPlayerId = this.form.get('tieBreakPlayer')?.value;
        if ((tieBreakPlayerId || '').length > 0) {
            game.tieBreakPlayerId = tieBreakPlayerId;
        }

        const request: AddGameRequest = { game, players: {} };

        if (game.gameId === newGameKey) {
            const newBoardGame = this.boardGames.find(boardGame => boardGame.key === newGameKey);
            if ((newBoardGame || null) === null) {
                this.error = 'Error adding new board game, please refresh and try again';
                return;
            }

            const requestBG = { ...newBoardGame, ...creationDetails };
            delete requestBG.key;

            request.boardGame = requestBG as BoardGame;
        }

        Object.keys(game.scores)
            .filter(playerKey => playerKey.indexOf(newPlayerKey) === 0)
            .forEach(playerKey => {
                const newPlayer = this.players.find(player => player.key === playerKey);
                if ((newPlayer || null) === null) {
                    this.error = 'Error adding new player, please refresh and try again';
                    return;
                }

                const requestPlayer = { ...newPlayer, ...creationDetails };
                delete requestPlayer.key;

                request.players[playerKey] = requestPlayer as Player;
            });

        this.store.dispatch(new AddGame(request))
            .pipe(untilDestroyed(this))
            .subscribe(() => this.router.navigateByUrl('scoreboard/games'));
    }

    private getScores(): Record<string, number> {
        return this.selectedPlayers.controls
            .reduce((map, control) => {
                const id = control.get('id')?.value;
                if ((id || '').length <= 0) {
                    return map;
                }

                map[control.get('id')?.value] = control.get('score')?.value;
                return map;
            }, {} as Record<string, number>);
    }

    private getWinningPlayers(): PlayerWithKey[] {
        const scores = this.getScores();
        const bestPlayerIds = getBestPlayersFor(scores);

        return bestPlayerIds
            .map(playerId => this.players.find(player => player.key === playerId))
            .filter(player => player !== undefined) as PlayerWithKey[];
    }
}
