import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';

import { map, tap } from 'rxjs/operators';
import { PLAYERS_PATH } from 'src/app/constants/database-paths';
import { UpdatePlayers } from 'src/app/scoreboard/store/scoreboard.actions';
import { FirebaseService } from 'src/app/shared/models/firebase-service';

import { Store } from '@ngxs/store';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Player } from '../models/player';

import firebase from 'firebase/app';

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class PlayerService extends FirebaseService {
    constructor(protected db: AngularFirestore, protected store: Store) {
        super();
    }

    public getAllPlayers(): void {
        this.db.collection<Player>(PLAYERS_PATH, ref => ref.orderBy('name'))
            .snapshotChanges()
            .pipe(
                untilDestroyed(this),
                map(snapshots => this.documentChangesToRecords(snapshots, player => {
                    return {
                        ...player,
                        dateCreated: player.dateCreated ? new Date(player.dateCreated) : undefined
                    };
                })),
                tap(players => this.store.dispatch(new UpdatePlayers(players)))
            )
            .subscribe();
    }

    public async addPlayer(player: Player): Promise<DocumentReference<Player>> {
        if (!player) {
            throw new Error('Player must not be null');
        }

        if (!!player.dateCreated) {
            player.dateCreated = new Date(player.dateCreated).toISOString();
        }

        const addedPlayer = await this.db.collection<Player>(PLAYERS_PATH).add(player);
        if (addedPlayer.id === null) {
            throw new Error('Unable to add new player');
        }
        return addedPlayer;
    }

    public async addGameToPlayer(id: string, key: string): Promise<void> {
        await this.db.doc(`${PLAYERS_PATH}/${id}`).update({
            games: firebase.firestore.FieldValue.arrayUnion(key)
        });
    }
}
