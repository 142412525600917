import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { USERS_PATH } from 'src/app/constants/database-paths';

import { Store } from '@ngxs/store';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DisplayUser, StoredUser } from '../models/user';
import { SetUser } from '../store/auth.actions';

import firebase from 'firebase/app';

@Injectable({
    providedIn: 'root'
})
@UntilDestroy()
export class AuthService {
    constructor(
        private fireAuth: AngularFireAuth,
        private db: AngularFirestore,
        private store: Store
    ) { }

    public googleSignIn(): Promise<firebase.auth.UserCredential> {
        return this.fireAuth
            .signInWithPopup(new firebase.auth.GoogleAuthProvider());
    }

    public signOut(): Promise<void> {
        return this.fireAuth.signOut();
    }

    public updateLoginState(): Observable<firebase.User | null> {
        return this.fireAuth.authState
            .pipe(
                untilDestroyed(this),
                tap(user => this.setUserData(user))
            );
    }

    private async setUserData(firebaseUser: firebase.User | null): Promise<void> {
        let user: DisplayUser | null = null;
        if (firebaseUser != null) {
            const dbUser = await this.getUser(firebaseUser.uid);
            user = this.storedUserToDisplayUser(firebaseUser.uid, firebaseUser.displayName || firebaseUser.uid, dbUser);
        }

        this.store.dispatch(new SetUser(user));
    }

    private async getUser(uid: string): Promise<StoredUser | null> {
        return await this.db.doc<StoredUser>(`${USERS_PATH}/${uid}`)
            .valueChanges()
            .pipe(
                untilDestroyed(this),
                take(1),
                map(user => user || null)
            )
            .toPromise();
    }

    private storedUserToDisplayUser(id: string, displayName: string, user: StoredUser | null): DisplayUser {
        let playerId = '';
        let isAuthorised = false;
        let isAdmin = false;

        if (user != null) {
            playerId = user.player;
            isAuthorised = user.authorised;
            isAdmin = user.roles?.includes('admin') === true;
        }

        return { id, displayName, playerId, isAdmin, isAuthorised };
    }
}
