import { DocumentChangeAction } from '@angular/fire/firestore';

export class FirebaseService {
    protected documentChangesToRecords<T>(
        changes: DocumentChangeAction<T>[],
        transformation: (item: T) => T
    ): Record<string, T> {
        return changes
            .filter(change => !!change.payload.doc.exists)
            .reduce((records, change) => {
                if (change.payload.doc !== null) {
                    let item = change.payload.doc.data();
                    item = transformation(item);
                    records[change.payload.doc.id] = item;
                }
                return records;
            }, {} as Record<string, T>);
    }
}
