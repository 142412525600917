<div class="game-container"
     (click)="toggleExpand()">
    <div class="game-details">
        <div class="game-name">{{ boardGamesMap[game.gameId]?.name || game.gameId }}</div>

        <ng-container *ngIf="winner?.length;">
            <div class="game-winner"
                 [class.user-player]="winnerIsUser">
                <mat-icon class="mr-1">workspace_premium</mat-icon>
                <span class="winner-label">{{ winner }}</span>
            </div>
        </ng-container>

        <ng-container *ngIf="isTie">
            <div class="game-winner tie">
                <mat-icon class="mr-1">balance</mat-icon>
                <span class="winner-label">Tie</span>
            </div>
        </ng-container>

        <div class="game-date">{{ game.date | date }}</div>
    </div>

    <ng-container *ngIf="expanded">
        <hr>

        <ng-container *ngIf="gamePlayers?.length; else defaultPlayersList">
            <div class="game-score"
                 *ngFor="let player of gamePlayers"
                 [class.user-player]="player.id === userPlayerId">
                <div class="player-name">
                    {{ player.name }}
                </div>
                <div class="player-score">{{ player.score }}</div>
            </div>
        </ng-container>

        <ng-template #defaultPlayersList>
            <div class="game-score"
                 *ngFor="let player of game.scores | keyvalue">
                <div class="player-name">{{ playersMap[player.key]?.name || player.key }}</div>
                <div class="player-score">{{ player.value }}</div>
            </div>
        </ng-template>
    </ng-container>
</div>