import { Component, OnInit } from '@angular/core';

import { Store } from '@ngxs/store';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DisplayUser } from '../auth/models/user';
import { AuthState } from '../auth/store/auth.state';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
@UntilDestroy()
export class HomeComponent implements OnInit {
    public loading = true;

    public user: DisplayUser | null = null;

    constructor(private store: Store) { }

    ngOnInit(): void {
        this.store.select(AuthState.user)
            .pipe(untilDestroyed(this))
            .subscribe(user => {
                this.user = user;
                if (this.user != null) {
                    this.loading = false;
                }
            });

        setTimeout(() => this.loading = false, 1000);
    }
}
