import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { Store } from '@ngxs/store';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DisplayUser } from '../models/user';
import { AuthService } from '../services/auth.service';
import { AuthState } from '../store/auth.state';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html'
})
@UntilDestroy()
export class LoginComponent implements OnInit {
    public loading = true;

    public user: DisplayUser | null = null;

    constructor(
        private store: Store,
        private authService: AuthService,
        private snackbar: MatSnackBar
    ) { }

    ngOnInit(): void {
        this.store.select(AuthState.user)
            .pipe(untilDestroyed(this))
            .subscribe(user => {
                this.user = user;
                if (this.user != null) {
                    this.loading = false;
                }
            });

        setTimeout(() => this.loading = false, 1000);
    }

    public signIn(): void {
        this.loading = true;

        this.authService.googleSignIn()
            .then()
            .catch(err => {
                this.loading = false;
                this.snackbar.open(err, 'Okay');
            })
            .finally(() => this.loading = false);
    }

    public signOut(): void {
        this.loading = true;

        this.authService.signOut()
            .then()
            .catch(err => {
                this.snackbar.open(err, 'Okay');
            })
            .finally(() => this.loading = false);
    }
}
