import { Component, OnInit } from '@angular/core';

import { Store } from '@ngxs/store';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthState } from '../auth/store/auth.state';
import { RefreshScoreboardData } from '../scoreboard/store/scoreboard.actions';
import { ScoreboardState } from '../scoreboard/store/scoreboard.state';
import { PlayerWithKey } from './models/player';

@Component({
    selector: 'app-players',
    templateUrl: './players.component.html',
    styleUrls: ['./players.component.scss']
})
@UntilDestroy()
export class PlayersComponent implements OnInit {
    public userPlayer: PlayerWithKey | undefined = undefined;
    public players: PlayerWithKey[] = [];

    userPlayerId = '';

    constructor(private store: Store) { }

    ngOnInit(): void {
        this.getStoreData();
    }

    public async getStoreData(): Promise<void> {
        this.store
            .select(AuthState.user)
            .pipe(untilDestroyed(this))
            .subscribe(user => this.userPlayerId = user?.playerId || '');

        this.store.select(ScoreboardState.playersArray)
            .pipe(untilDestroyed(this))
            .subscribe(players => this.setPlayers(players));

        this.store.dispatch(new RefreshScoreboardData());
    }

    private setPlayers(players: PlayerWithKey[]): void {
        this.players = players;
        this.userPlayer = this.players.find(player => player.key === this.userPlayerId);
    }
}
