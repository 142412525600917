import { Injectable } from '@angular/core';

import { getWinnerIdFor } from 'src/app/functions/game.functions';
import { BoardGameWithKey } from 'src/app/scoreboard/models/board-game';
import { GameWithKey } from 'src/app/scoreboard/models/game';
import { ScoreboardState } from 'src/app/scoreboard/store/scoreboard.state';

import { Store } from '@ngxs/store';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChartItem, DEFAULT_PLAYER_STATS, GameStats, PlayerStats, PlayerStatsHelper } from '../models/player-stats';

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class StatsService {
    private boardGames: BoardGameWithKey[] = [];

    constructor(private store: Store) {
        this.getBoardGames();
    }

    public getStatsFromGames(games: GameWithKey[], currentPlayerId: string): PlayerStats {
        if ((games || []).length === 0) {
            return DEFAULT_PLAYER_STATS;
        }
        if ((this.boardGames || []).length === 0) {
            return DEFAULT_PLAYER_STATS;
        }

        const gameStats = this.getStatsHelpers(games, currentPlayerId);

        const orderedGames = gameStats.games
            .sort((a, b) => a.name.localeCompare(b.name));
        const favouriteGames = orderedGames
            .map<ChartItem>(group => ({ name: group.name, value: group.totalGames }));
        const bestGames = orderedGames
            .map<ChartItem>(group => ({ name: group.name, value: group.gamesWon / group.totalGames * 100 }));

        return {
            gameCount: games.length,
            overallWinPercentage: gameStats.allGameStats.gamesWon / gameStats.allGameStats.totalGames * 100,
            favouriteGames,
            bestGames
        };
    }

    private getBoardGames(): void {
        this.store.select(ScoreboardState.boardGamesArray)
            .pipe(untilDestroyed(this))
            .subscribe((boardGames) => {
                this.boardGames = boardGames;
            });
    }

    private getStatsHelpers(games: GameWithKey[], currentPlayerId: string): PlayerStatsHelper {
        const overallHelper = new GameStats('overall');

        const gameGroups = games.reduce((gameMap, game) => {
            let helper = gameMap[game.gameId];
            if (!helper) {
                const boardGame = this.boardGames.find(bg => bg.key === game.gameId);
                if (!boardGame) {
                    return gameMap;
                }

                helper = new GameStats(boardGame.name);
                gameMap[game.gameId] = helper;
            }

            helper.totalGames++;
            overallHelper.totalGames++;

            const winnerId = getWinnerIdFor(game);
            if (winnerId === currentPlayerId) {
                helper.gamesWon++;
                overallHelper.gamesWon++;
            }

            return gameMap;
        }, {} as Record<string, GameStats>);

        return {
            allGameStats: overallHelper,
            games: Object.values(gameGroups)
        };
    }
}
