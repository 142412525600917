<form [formGroup]="form"
      (ngSubmit)="save()">
    <!-- BOARD GAME SELECT -->
    <mat-form-field>
        <mat-label>Game</mat-label>
        <mat-select formControlName="gameId"
                    (selectionChange)="onGameSelected($event)">
            <ng-container *ngFor="let boardGame of boardGames">
                <mat-option *ngIf="!!boardGame.key"
                            [value]="boardGame.key">{{ boardGame.name }}</mat-option>
            </ng-container>
            <mat-option class="new-game-option"
                        [value]="newGameKey">
                <mat-icon>add_circle</mat-icon>
                New Game
            </mat-option>
        </mat-select>
    </mat-form-field>

    <!-- DATE INPUT -->
    <mat-form-field>
        <mat-label>Choose a date</mat-label>
        <input matInput
               [matDatepicker]="picker"
               [max]="maxDate"
               formControlName="date"
               (click)="picker.open()">
        <mat-datepicker-toggle matSuffix
                               [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker
                        disabled="false"></mat-datepicker>
    </mat-form-field>

    <div class="mat-headline mt-4 mb-2 ta-c">
        <mat-icon>person</mat-icon> Players
    </div>

    <!-- PLAYER SCORE ARRAY -->
    <ng-container formArrayName="players">
        <ng-container *ngFor="let playerForm of selectedPlayers.controls; let i = index">
            <div class="player-score-container"
                 [formGroupName]="i">
                <div class="player-inputs">
                    <mat-form-field class="player-name">
                        <mat-label>Player</mat-label>
                        <mat-select formControlName="id"
                                    (selectionChange)="onPlayerSelected($event, i)">
                            <ng-container *ngFor="let player of players">
                                <mat-option *ngIf="!!player.key"
                                            [value]="player.key"
                                            [disabled]="playerIsSelected(i, player.key)">
                                    {{ player.name }}
                                </mat-option>
                            </ng-container>
                            <mat-option class="new-game-option"
                                        [value]="newPlayerKey">
                                <mat-icon>add_circle</mat-icon>
                                New Player
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="player-score">
                        <mat-label>Score</mat-label>
                        <input matInput
                               type="number"
                               formControlName="score"
                               (input)="setTieBreakValues()">
                    </mat-form-field>
                </div>

                <div class="button-container">
                    <button mat-mini-fab
                            type="button"
                            color="warn"
                            (click)="removePlayer(i)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <button mat-flat-button
            class="add-player-button"
            type="button"
            color="primary"
            (click)="addPlayer()">
        Add player
        <mat-icon class="ml-1">add_circle</mat-icon>
    </button>

    <div class="ta-c warn my-2">{{error}}</div>

    <!-- TIE BREAK -->
    <ng-container *ngIf="tieBreakPlayers.length > 1">
        <mat-form-field class="player-name mb-4">
            <mat-label>Tie break player</mat-label>
            <mat-select formControlName="tieBreakPlayer">
                <mat-option *ngFor="let player of tieBreakPlayers"
                            [value]="player.key">
                    {{ player.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>

    <!-- CONFIRM / BACK BUTTONS -->
    <div class="flex">
        <a mat-flat-button
           type="button"
           routerLink="../../games">
            <mat-icon class="mr-1">arrow_back</mat-icon>
            Back
        </a>

        <span class="flex-spacer"></span>

        <button mat-flat-button
                type="submit"
                color="primary">
            Save
            <mat-icon class="ml-1">save</mat-icon>
        </button>
    </div>
</form>