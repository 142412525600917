import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn } from '@angular/fire/firestore';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BOARD_GAMES_PATH, GAMES_PATH, PLAYERS_PATH } from 'src/app/constants/database-paths';
import { newPlayerKey } from 'src/app/players/models/player';
import { PlayerService } from 'src/app/players/services/player.service';
import { FirebaseService } from 'src/app/shared/models/firebase-service';

import { Store } from '@ngxs/store';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AddGameRequest } from '../models/add-game-request';
import { BoardGame } from '../models/board-game';
import { Game } from '../models/game';
import { UpdateBoardGames, UpdateGames } from '../store/scoreboard.actions';

import firebase from 'firebase/app';

@UntilDestroy()
@Injectable({
    providedIn: 'root'
})
export class ScoreboardService extends FirebaseService {
    constructor(protected db: AngularFirestore, protected store: Store, private playerService: PlayerService) {
        super();
    }

    // ** BOARD GAMES **

    public getAllBoardGames(): void {
        this.db.collection<BoardGame>(BOARD_GAMES_PATH)
            .snapshotChanges()
            .pipe(
                untilDestroyed(this),
                map(boardGames => this.documentChangesToRecords(boardGames, boardGame => {
                    return {
                        ...boardGame,
                        dateCreated: boardGame.dateCreated ? new Date(boardGame.dateCreated) : undefined
                    };
                })),
                tap(boardGames => this.store.dispatch(new UpdateBoardGames(boardGames)))
            )
            .subscribe();
    }

    // ** GAMES **

    public getAllGames(): void {
        this.getGames(undefined)
            .pipe(tap(games => this.store.dispatch(new UpdateGames(games))))
            .subscribe();
    }

    public getGamesForPlayer(key: string): Observable<Record<string, Game>> {
        return this.getGames(ref => ref.where('players', 'array-contains', key));
    }

    public async addGame(addGameRequest: AddGameRequest): Promise<void> {
        await this.addNewBoardGame(addGameRequest);
        await this.addNewPlayers(addGameRequest);

        addGameRequest.game.date = new Date(addGameRequest.game.date).toISOString();
        if (!!addGameRequest.game.dateCreated) {
            addGameRequest.game.dateCreated = new Date(addGameRequest.game.dateCreated).toISOString();
        }

        const addedGame = await this.db.collection<Game>(GAMES_PATH).add(addGameRequest.game);
        if (addedGame.id === null) {
            return;
        }
        const key = addedGame.id;

        await this.addGameToBoardGame(addGameRequest.game.gameId, key);

        const playerUpdatePromises = Object.keys(addGameRequest.game.scores).map(async (playerKey: string) =>
            await this.playerService.addGameToPlayer(playerKey, key)
        );
        await Promise.all(playerUpdatePromises);
    }

    // ** HELPERS **

    private getGames(query: QueryFn<firebase.firestore.DocumentData> | undefined): Observable<Record<string, Game>> {
        return this.db.collection<Game>(GAMES_PATH, query)
            .snapshotChanges()
            .pipe(
                untilDestroyed(this),
                map(games => this.documentChangesToRecords(games, game => {
                    return {
                        ...game,
                        date: new Date(game.date),
                        dateCreated: game.dateCreated ? new Date(game.dateCreated) : undefined
                    };
                })
                )
            );
    }

    private async addNewBoardGame(request: AddGameRequest): Promise<void> {
        if (!request.boardGame) {
            return;
        }

        if (!!request.boardGame.dateCreated) {
            request.boardGame.dateCreated = new Date(request.boardGame.dateCreated).toISOString();
        }

        const addedBoardGame = await this.db.collection<BoardGame>(BOARD_GAMES_PATH).add(request.boardGame);
        if (addedBoardGame.id === null) {
            throw new Error('Unable to add new board game');
        }

        request.game.gameId = addedBoardGame.id;
    }

    private async addNewPlayers(request: AddGameRequest): Promise<void> {
        if (Object.keys(request.players).length === 0) {
            return;
        }

        const promises = Object.keys(request.game.scores)
            .filter(key => key.indexOf(newPlayerKey) === 0)
            .map(async key => {
                const player = request.players[key];
                const addedPlayer = await this.playerService.addPlayer(player);

                const scoreValue = request.game.scores[key];
                delete request.game.scores[key];
                request.game.scores[addedPlayer.id] = scoreValue;

                request.game.players = request.game.players.filter(p => p !== key);
                request.game.players.push(addedPlayer.id);

                if (request.game.tieBreakPlayerId === key) {
                    request.game.tieBreakPlayerId = addedPlayer.id;
                }
            });

        await Promise.all(promises);
    }

    private async addGameToBoardGame(id: string, key: string): Promise<void> {
        await this.db.doc(`${BOARD_GAMES_PATH}/${id}`).update({
            games: firebase.firestore.FieldValue.arrayUnion(key)
        });
    }
}
