export interface PlayerStats {
    gameCount: number;
    overallWinPercentage: number;
    favouriteGames: ChartItem[];
    bestGames: ChartItem[];
}

export interface ChartItem {
    name: string;
    value: number;
}

export const DEFAULT_PLAYER_STATS: PlayerStats = {
    gameCount: 0,
    overallWinPercentage: 0,
    favouriteGames: [],
    bestGames: []
};

export interface PlayerStatsHelper {
    allGameStats: GameStats;
    games: GameStats[];
}

export class GameStats {
    constructor(public name: string) { }

    totalGames = 0;
    gamesWon = 0;
}

export const CHART_COLOURS: string[] = [
    '#c9e2c2',
    '#a5cf99',
    '#80bb70',
    '#65ad52',
    '#4a9e33',
    '#43962e',
    '#3a8c27',
    '#328220',
    '#227014',
    '#1e6212'
];
