import { Injectable } from '@angular/core';

import { Action, Selector, State, StateContext } from '@ngxs/store';

import { AuthStateModel } from '../models/auth-state.model';
import { DisplayUser } from '../models/user';
import { SetUser } from './auth.actions';

@State<AuthStateModel>({
    name: 'auth',
    defaults: {
        user: null
    }
})
@Injectable()
export class AuthState {
    @Selector()
    static user(state: AuthStateModel): DisplayUser | null {
        return state.user;
    }

    @Action(SetUser)
    setUser(ctx: StateContext<AuthStateModel>, action: SetUser): void {
        ctx.patchState({
            user: action.user
        });
    }
}
