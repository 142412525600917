import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { FirebaseModule } from './firebase.module';
import { GameDetailsComponent } from './game-details/game-details.component';
import { LoadingComponent } from './loading/loading.component';
import { MaterialModule } from './material.module';
import { PromptComponent } from './prompt/prompt.component';

@NgModule({
    declarations: [
        GameDetailsComponent,
        LoadingComponent,
        PromptComponent
    ],
    imports: [
        CommonModule,
        FirebaseModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        NgxChartsModule
    ],
    exports: [
        FirebaseModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        NgxChartsModule,

        GameDetailsComponent,
        LoadingComponent
    ]
})
export class SharedModule { }
