import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { PlayerWithKey } from '../models/player';

@Component({
    selector: 'app-player-details-mini',
    templateUrl: './player-details-mini.component.html',
    styleUrls: ['./player-details-mini.component.scss']
})
export class PlayerDetailsMiniComponent implements OnInit {
    @Input() player!: PlayerWithKey;
    @Input() highlight = false;

    public gameCount = 0;

    constructor(private router: Router) { }

    ngOnInit(): void {
        if (!this.player) {
            return;
        }

        this.gameCount = (this.player.games || []).length;
    }

    public openPlayerPage(): void {
        this.router.navigate(['players', this.player.key]);
    }
}
