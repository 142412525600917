import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Store } from '@ngxs/store';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthState } from '../store/auth.state';

@Injectable({
    providedIn: 'root'
})
@UntilDestroy()
export class LoginGuard implements CanActivate {
    constructor(private store: Store, private router: Router) { }

    canActivate(): Observable<boolean> {
        return this.store.select(AuthState.user)
            .pipe(
                untilDestroyed(this),
                map(user => {
                    if (user != null && user.isAuthorised) {
                        return true;
                    }

                    this.router.navigate([]);
                    return false;
                })
            );
    }
}
