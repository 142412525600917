<div class="main-container">
    <h1>The Scoreboard</h1>

    <ng-container *ngIf="!loading; else loadingRef">
        <ng-container *ngIf="user; else notLoggedIn">
            <div *ngIf="user.isAuthorised; else notAuthorised"
                 class="dashboard-buttons">
                <a mat-flat-button
                   color="primary"
                   routerLink="/games">
                    Games
                    <mat-icon class="ml-1">casino</mat-icon>
                </a>

                <a mat-flat-button
                   color="primary"
                   [routerLink]="'/players/' + user.playerId">
                    My stats
                    <mat-icon class="ml-1">person</mat-icon>
                </a>

                <a mat-flat-button
                   color="primary"
                   routerLink="/games/0">
                    Add Game
                    <mat-icon class="ml-1">add_circle</mat-icon>
                </a>
            </div>

            <ng-template #notAuthorised>
                <h2>You haven't been approved to use The Scoreboard yet...</h2>
                <h3>Please contact the owner for authorisation</h3>
            </ng-template>
        </ng-container>

        <ng-template #notLoggedIn>
            <a mat-flat-button
               color="primary"
               routerLink="/login">Log In</a>
        </ng-template>
    </ng-container>

    <ng-template #loadingRef>
        <mat-spinner diameter="36"></mat-spinner>
    </ng-template>
</div>