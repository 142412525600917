import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgxsModule } from '@ngxs/store';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { AuthState } from './auth/store/auth.state';
import { HomeComponent } from './home/home.component';
import { PlayersModule } from './players/players.module';
import { AddGameComponent } from './scoreboard/add-game/add-game.component';
import { BoardGamesComponent } from './scoreboard/board-games/board-games.component';
import { AddBoardGameComponent } from './scoreboard/dialogs/add-board-game/add-board-game.component';
import { ScoreboardState } from './scoreboard/store/scoreboard.state';
import { SharedModule } from './shared/shared.module';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        BoardGamesComponent,
        AddGameComponent,
        AddBoardGameComponent,
        LoginComponent
    ],
    imports: [
        PlayersModule,

        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,

        NgxsModule.forRoot([AuthState, ScoreboardState]),

        SharedModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
