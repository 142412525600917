import { Game } from '../scoreboard/models/game';

export function getWinnerIdFor(game: Game): string | null {
    const bestPlayerIds = getBestPlayersFor(game.scores);

    if (!bestPlayerIds.length) {
        return null;
    }

    if (bestPlayerIds.length === 1) {
        return bestPlayerIds[0];
    }

    if (!game.tieBreakPlayerId) {
        return null;
    }

    return game.tieBreakPlayerId;
}

export function getBestPlayersFor(scores: Record<string, number>): string[] {
    let maxScore = 0;
    let bestPlayerIds: string[] = [];

    Object.entries(scores).forEach(([playerId, score]) => {
        if (score > maxScore) {
            maxScore = score;
            bestPlayerIds = [playerId];
        } else if (score === maxScore) {
            bestPlayerIds.push(playerId);
        }
    });

    return bestPlayerIds;
}
