import { Component, OnInit } from '@angular/core';

import { Store } from '@ngxs/store';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DisplayUser } from './auth/models/user';
import { AuthService } from './auth/services/auth.service';
import { AuthState } from './auth/store/auth.state';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
@UntilDestroy()
export class AppComponent implements OnInit {
    title = 'the-scoreboard';

    public user: DisplayUser | null = null;

    constructor(private authService: AuthService, private store: Store) { }

    ngOnInit(): void {
        this.store.select(AuthState.user)
            .pipe(untilDestroyed(this))
            .subscribe(user => this.user = user);

        this.authService.updateLoginState()
            .pipe(untilDestroyed(this))
            .subscribe();
    }
}
