<div class="ta-c mt-2">
    <ng-container *ngIf="!loading; else loadingDisplay">
        <!-- ** ACCOUNT AND SIGN OUT ** -->
        <ng-container *ngIf="user; else logInRef">
            <h1 class="mat-display-1 mt-4 mb-2">Logged in as {{user.displayName}}</h1>

            <ng-container *ngIf="!user.isAuthorised">
                <h2>Current user is not approved to use The Scoreboard</h2>
                <h3>Contact the owner for authorisation</h3>
            </ng-container>

            <h3 *ngIf="user.isAdmin">Current user is an admin user</h3>

            <button type="button"
                    mat-flat-button
                    class="mx-a"
                    (click)="signOut()">
                Log out
            </button>
        </ng-container>

        <!-- ** SIGN IN ** -->
        <ng-template #logInRef>
            <h1 class="mat-display-1 mt-4 mb-2">Log in</h1>
            <button type="button"
                    mat-flat-button
                    color="primary"
                    class="mt-2 mx-a"
                    (click)="signIn()">
                <i class="fab fa-google-plus-g"></i>
                Log in with Google
            </button>
        </ng-template>
    </ng-container>
</div>

<ng-template #loadingDisplay>
    <app-loading></app-loading>
</ng-template>