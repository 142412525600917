import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { AddPlayerComponent } from './dialogs/add-player/add-player.component';
import { PlayerDetailsMiniComponent } from './player-details-mini/player-details-mini.component';
import { PlayerDetailsComponent } from './player-details/player-details.component';
import { PlayersComponent } from './players.component';

@NgModule({
    declarations: [
        AddPlayerComponent,
        PlayersComponent,
        PlayerDetailsMiniComponent,
        PlayerDetailsComponent
    ],
    imports: [
        CommonModule,
        SharedModule
    ],
    exports: [
        AddPlayerComponent,
        PlayersComponent,
        PlayerDetailsComponent
    ]
})
export class PlayersModule { }
