import { Component, Input, OnInit } from '@angular/core';

import { AuthState } from 'src/app/auth/store/auth.state';
import { getWinnerIdFor } from 'src/app/functions/game.functions';
import { Player, PlayerScore } from 'src/app/players/models/player';

import { Store } from '@ngxs/store';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BoardGame } from '../../scoreboard/models/board-game';
import { Game } from '../../scoreboard/models/game';
import { ScoreboardState } from '../../scoreboard/store/scoreboard.state';

@Component({
    selector: 'app-game-details',
    templateUrl: './game-details.component.html',
    styleUrls: ['./game-details.component.scss']
})
@UntilDestroy()
export class GameDetailsComponent implements OnInit {
    @Input() public key!: string;
    @Input() public game!: Game;

    public gamePlayers: PlayerScore[] = [];

    public boardGamesMap: Record<string, BoardGame> = {};
    public playersMap: Record<string, Player> = {};
    public userPlayerId = '';
    public expanded = false;

    public winner = '';
    public winnerIsUser = false;
    public isTie = false;

    constructor(private store: Store) { }

    ngOnInit(): void {
        this.getStoreData();
    }

    public toggleExpand(): void {
        this.expanded = !this.expanded;
    }

    private getStoreData(): void {
        this.store
            .select(ScoreboardState.playersMap)
            .pipe(untilDestroyed(this))
            .subscribe(map => {
                this.playersMap = map;
                this.updateGame();
            });

        this.store
            .select(ScoreboardState.boardGamesMap)
            .pipe(untilDestroyed(this))
            .subscribe(map => this.boardGamesMap = map);

        this.store
            .select(AuthState.user)
            .pipe(untilDestroyed(this))
            .subscribe(user => {
                this.userPlayerId = user?.playerId || '';
                this.setWinner();
            });
    }

    private updateGame(): void {
        this.gamePlayers = Object.entries(this.game.scores)
            .map<PlayerScore>(([id, score]) => {
                return {
                    id,
                    score,
                    name: this.playersMap[id]?.name
                };
            })
            .sort((a, b) => b.score - a.score);

        this.setWinner();
    }

    private setWinner(): void {
        const winnerId = getWinnerIdFor(this.game);

        if (!winnerId) {
            this.setTie();
            return;
        }

        const winnerPlayer = this.playersMap[winnerId];
        if (!winnerPlayer) {
            this.setTie();
            return;
        }

        this.winner = winnerPlayer.name;
        this.winnerIsUser = winnerId === this.userPlayerId;
        this.isTie = false;
    }

    private setTie(): void {
        this.winner = '';
        this.winnerIsUser = false;
        this.isTie = true;
    }
}
