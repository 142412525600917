<div class="mat-display-1 mt-4 mb-2 ta-c">Players</div>

<div class="ta-c">
    <app-player-details-mini *ngIf="userPlayer"
                             [player]="userPlayer"
                             [highlight]="true"></app-player-details-mini>

    <ng-container *ngFor="let player of players">
        <app-player-details-mini *ngIf="player && player !== userPlayer"
                                 [player]="player"></app-player-details-mini>
    </ng-container>
</div>