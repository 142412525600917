<h1 class="mat-display-1 ta-c my-4">Games</h1>

<div class="ta-c">
    <a mat-flat-button
       color="primary"
       routerLink="/games/0">
        Add Game
        <mat-icon class="ml-1">add_circle</mat-icon>
    </a>
</div>

<div class="ta-c">
    <app-game-details *ngFor="let game of games"
                      [key]="game.key || ''"
                      [game]="game"></app-game-details>
</div>