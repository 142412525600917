import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { shuffle } from 'src/app/functions/array.functions';
import { GameWithKey } from 'src/app/scoreboard/models/game';
import { ScoreboardService } from 'src/app/scoreboard/services/scoreboard.service';
import { RefreshScoreboardData } from 'src/app/scoreboard/store/scoreboard.actions';
import { ScoreboardState } from 'src/app/scoreboard/store/scoreboard.state';

import { Store } from '@ngxs/store';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Player } from '../models/player';
import { CHART_COLOURS, DEFAULT_PLAYER_STATS, PlayerStats } from '../models/player-stats';
import { StatsService } from '../services/stats.service';

@Component({
    selector: 'app-player-details',
    templateUrl: './player-details.component.html',
    styleUrls: ['./player-details.component.scss']
})
@UntilDestroy()
export class PlayerDetailsComponent implements OnInit {
    public player: Player | null = null;
    public games: GameWithKey[] = [];
    public stats: PlayerStats = DEFAULT_PLAYER_STATS;
    public loading = true;
    public hasStats = false;

    public readonly colourScheme = {
        domain: ['']
    };

    private readonly idRouteParameter = 'id';
    private playerId = '';

    constructor(
        private route: ActivatedRoute,
        private scoreboardService: ScoreboardService,
        private store: Store,
        private statsService: StatsService
    ) { }

    ngOnInit(): void {
        this.colourScheme.domain = shuffle(CHART_COLOURS);

        this.route.params
            .pipe(untilDestroyed(this))
            .subscribe(params => {
                this.playerId = params[this.idRouteParameter];
            });

        this.store.select(ScoreboardState.playersMap)
            .pipe(untilDestroyed(this))
            .subscribe(playerMap => {
                this.player = playerMap[this.playerId];
                this.loadGamesForPlayer();
            });

        this.store.dispatch(new RefreshScoreboardData());
    }

    public toRoundNumber(value: number): string {
        return value.toString();
    }

    public toPercentage(value: number): string {
        return Math.round(value) + '%';
    }

    async loadGamesForPlayer(): Promise<void> {
        if (!this.player) {
            return;
        }

        this.loading = true;

        this.scoreboardService.getGamesForPlayer(this.playerId)
            .pipe(untilDestroyed(this))
            .subscribe(gamesMap => {
                this.games = Object.values(gamesMap)
                    .sort((a, b) => a.date > b.date ? -1 : 1);
                this.loadStatsForPlayer();
            });

        this.loading = false;
    }

    loadStatsForPlayer(): void {
        this.stats = this.statsService.getStatsFromGames(this.games, this.playerId);
        this.hasStats = this.stats !== DEFAULT_PLAYER_STATS;
    }
}
