import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginGuard } from './auth/guards/login.guard';
import { LoginComponent } from './auth/login/login.component';
import { HomeComponent } from './home/home.component';
import { PlayerDetailsComponent } from './players/player-details/player-details.component';
import { PlayersComponent } from './players/players.component';
import { AddGameComponent } from './scoreboard/add-game/add-game.component';
import { BoardGamesComponent } from './scoreboard/board-games/board-games.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'games',
        component: BoardGamesComponent,
        canActivate: [LoginGuard]
    },
    {
        path: 'games/:id',
        component: AddGameComponent,
        canActivate: [LoginGuard]
    },
    {
        path: 'players',
        component: PlayersComponent,
        canActivate: [LoginGuard]
    },
    {
        path: 'players/:id',
        component: PlayerDetailsComponent,
        canActivate: [LoginGuard]
    },
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: '**',
        redirectTo: 'games'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
